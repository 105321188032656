// src/Tracking.js
import React, { useState } from 'react';
import { default as axios } from 'axios';

const Tracking = () => {
  const [inputValue, setInputValue] = useState('');
  const [trackingUrl, setTrackingUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    let value = e.target.value;

    // Remove all non-digit characters
    value = value.replace(/\D/g, "");

    // Format the input value for display
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    setInputValue(value);
  };

  const fetchOrderDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true

    try {
      const strippedCpf = inputValue.replace(/\D/g, ""); // Strip special chars before sending request
      const response = await axios.get(`https://tracking-app-backend-b85r.onrender.com/getOrderDetails/${strippedCpf}`);

      const trackingData = response.data;
      let tracking = trackingData[0] === null ? "Pedido encontrado mas ainda não foi gerado código de rastreio" : trackingData;

      setTrackingUrl(tracking);
    } catch (error) {
      setTrackingUrl("Pedido não encontrado ou ainda não foi enviado para a transportadora.")
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const navigateToUrl = (url) => {
    window.location.href = url;
  };

  return (
    <main className="grid min-h-screen place-items-center bg-white px-6 py-24 lg:px-8">
      <div className="text-center">
        <img className="mx-auto" src="https://thebarboursbeauty.com.br/cdn/shop/files/Branco_alta_1_600x.png?v=1697057653" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Faça o rastreio do seu pedido</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Digite o seu CPF para buscar</p>
        <form onSubmit={fetchOrderDetails} className="p-6 bg-white rounded shadow-md">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="XXX.XXX.XXX-XX"
            className="p-2 border rounded w-full mb-4"
            maxLength="14"
          />
          <button
            type="submit"
            className={`bg-black text-white p-2 rounded w-full ${!inputValue.trim() ? 'cursor-not-allowed' : ''}`}
            disabled={isLoading || !inputValue.trim()}
          >
            {isLoading ? 'Carregando...' : 'Pesquisar'}
          </button>
        </form>
      </div>

      {Array.isArray(trackingUrl) && trackingUrl.length ?
        <div>
          <ul role="list" className="divide-y divide-gray-100 border p-5 border-solid border-black rounded-md mt-10">

            <li  className="relative flex justify-between gap-x-6 py-5" onClick={() => navigateToUrl(trackingUrl[0])}>
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <a href="#">
                      <span className="absolute inset-x-0 -top-px bottom-0"></span>
                      <p className="text-sm leading-6 text-gray-900">Pedido {trackingUrl[1]}</p>
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-4">
                <div className="sm:flex sm:flex-col sm:items-end">
                  <p className="mt-1 text-xs leading-5 text-black">Ver Rastreio</p>
                </div>
                <svg className="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                </svg>
              </div>
            </li>

          </ul>
        </div>
        :
        <div>
          <p> {trackingUrl}</p>
        </div>
      }
    </main>
  );
};

export default Tracking;
